import instance from './axios';

const axiosInstance = instance;

// login via email and password
/**
 * Admin login
 * @param {Object} data email and password
 */
export const getEnquires = async () => {
    try {
        const result = await axiosInstance.get('/contact');
        const data = result.data?.contacts || [];
        return data;
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}

/**
* Delete enquiry
* @param {Object} enquiryId enquiry id
*/
export const deleteEnquiry = async (enquiryId) => {
    try {
        const result = await axiosInstance.delete(`/contact/${enquiryId}`);
        const message = result.data?.message || '';
        return {success: true, message};
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return {success: false, message: errorMessage};

    }
}
